import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
            <Helmet>
        <meta charSet="utf-8" />
        <title>Election Cartograms</title>
        <meta name="description" content="Election Cartograms" />
        <meta name="image" content="https://awmcphee.ca/cartogram.png" />
        <meta property="og:image" content="https://awmcphee.ca/cartogram.png"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Election Cartograms"/>
        <meta name="twitter:description" content="Because land doesn't vote."/>
        <meta name="twitter:image" content="https://awmcphee.ca/cartogram.png"/>
      </Helmet>
      <h1>Election Cartograms</h1>
      <h2>Because land doesn't vote</h2>
      <p>Thank you to <a className="plainlink" href="https://twitter.com/Arnold_Platon" target="_blank" rel="noopener noreferrer">Arnold Platon</a> for originally introducing me to these striking grid cartograms! This style was developed by a Hungarian demographer around the turn of the last century.</p>
      <p>Nothing is exaggerated or minimized: every electoral district is shown at equal size.</p>
      <h2 id="canada">Canadian Federal Elections</h2>
      <div className="portfolio">
      <a href="/canada_1968.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_1968.png"
            alt="A grid cartogram depicting the results of Canada's 1968 federal election."
          />
          <h4>1968</h4>
          <span><em>P. Trudeau I</em></span>
        </div></a>
        <a href="/canada_1984.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_1984.png"
            alt="A grid cartogram depicting the results of Canada's 1984 federal election."
          />
          <h4>1984</h4>
          <span><em>Mulroney I</em></span>
        </div></a>
        <a href="/canada_1988.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_1988.png"
            alt="A grid cartogram depicting the results of Canada's 1988 federal election."
          />
          <h4>1988</h4>
          <span><em>Mulroney II</em></span>
        </div></a>
        <a href="/canada_1993.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_1993.png"
            alt="A grid cartogram depicting the results of Canada's 1993 federal election."
          />
          <h4>1993</h4>
          <span><em>Chrétien I</em></span>
        </div></a>
        <a href="/canada_2008.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_2008.png"
            alt="A grid cartogram depicting the results of Canada's 2008 federal election."
          />
          <h4>2008</h4>
          <span><em>Harper II</em></span>
        </div></a>
        <a href="/canada_2011.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_2011.png"
            alt="A grid cartogram depicting the results of Canada's 2011 federal election."
          />
          <h4>2011</h4>
          <span><em>Harper III</em></span>
        </div></a>
        <a href="/canada_2015.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_2015.png"
            alt="A grid cartogram depicting the results of Canada's 2015 federal election."
          />
          <h4>2015</h4>
          <span><em>J. Trudeau I</em></span>
        </div></a>
        <a href="/canada_2019.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_2019.png"
            alt="A grid cartogram depicting the results of Canada's 2019 federal election."
          />
          <h4>2019</h4>
          <span><em>J. Trudeau II</em></span>
        </div></a>
        <a href="/canada_2021.png" target="_blank" rel="noopener noreferrer"><div className="pf">
          <StaticImage
            src="../cartograms/canada/canada_2021.png"
            alt="A grid cartogram depicting the results of Canada's 2021 federal election."
          />
          <h4>2021</h4>
          <span><em>J. Trudeau III</em></span>
        </div></a>
      </div>
      <h2>Canadian Provincial Legislatures</h2>
      <div className="awards"><a href="/canada_provinces.png" target="_blank" rel="noopener noreferrer">
      <StaticImage src="../cartograms/canada_provinces.png" alt="A grid cartogram depicting the popular vote in every Canadian provincial legislature." className="bigmap"/>
      </a></div>
      <p>Updated every provincial election. Prints at a deluxe 72” x 24”.</p>
      <h2 id="saskatchewan">Saskatchewan Provincial Elections</h2>
      <div className="portfolio">
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1905 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1905 provincial election."
          />
          <h4>1905</h4>
          <span><em>Scott I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1908 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1908 provincial election."
          />
          <h4>1908</h4>
          <span><em>Scott II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1912 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1912 provincial election."
          />
          <h4>1912</h4>
          <span><em>Scott III, Martin I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1917 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1917 provincial election."
          />
          <h4>1917</h4>
          <span><em>Martin II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1921 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1921 provincial election."
          />
          <h4>1921</h4>
          <span><em>Martin III, Dunning I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1925 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1925 provincial election."
          />
          <h4>1925</h4>
          <span><em>Dunning II, Gardiner I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1929 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1929 provincial election."
          />
          <h4>1929</h4>
          <span><em>Gardiner II, Anderson I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1934 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1934 provincial election."
          />
          <h4>1934</h4>
          <span><em>Gardiner III, Patterson I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1938 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1938 provincial election."
          />
          <h4>1938</h4>
          <span><em>Patterson II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1944 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1944 provincial election."
          />
          <h4>1944</h4>
          <span><em>Douglas I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1948 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1948 provincial election."
          />
          <h4>1948</h4>
          <span><em>Douglas II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1952 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1952 provincial election."
          />
          <h4>1952</h4>
          <span><em>Douglas III</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1956 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1956 provincial election."
          />
          <h4>1956</h4>
          <span><em>Douglas IV</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1960 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1960 provincial election."
          />
          <h4>1960</h4>
          <span><em>Douglas V, Lloyd I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1964 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1964 provincial election."
          />
          <h4>1964</h4>
          <span><em>Thatcher I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1967 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1967 provincial election."
          />
          <h4>1967</h4>
          <span><em>Thatcher II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1971 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1971 provincial election."
          />
          <h4>1971</h4>
          <span><em>Blakeney I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1975 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1975 provincial election."
          />
          <h4>1975</h4>
          <span><em>Blakeney II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1978 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1978 provincial election."
          />
          <h4>1978</h4>
          <span><em>Blakeney III</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1982 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1982 provincial election."
          />
          <h4>1982</h4>
          <span><em>Devine I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1986 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1986 provincial election."
          />
          <h4>1986</h4>
          <span><em>Devine II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1991 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1991 provincial election."
          />
          <h4>1991</h4>
          <span><em>Romanow I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1995 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1995 provincial election."
          />
          <h4>1995</h4>
          <span><em>Romanow II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 1999 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 1999 provincial election."
          />
          <h4>1999</h4>
          <span><em>Romanow III, Calvert I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 2003 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 2003 provincial election."
          />
          <h4>2003</h4>
          <span><em>Calvert II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 2007 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 2007 provincial election."
          />
          <h4>2007</h4>
          <span><em>Wall I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 2011 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 2011 provincial election."
          />
          <h4>2011</h4>
          <span><em>Wall II</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 2016 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 2016 provincial election."
          />
          <h4>2016</h4>
          <span><em>Wall III, Moe I</em></span>
        </div>
        <div className="pfs">
          <StaticImage
            src="../cartograms/saskatchewan/Saskatchewan 2020 Election preview.png"
            alt="A grid cartogram depicting the results of Saskatchewan's 2020 provincial election."
          />
          <h4>2020</h4>
          <span><em>Moe II</em></span>
        </div>
      </div>
      <p>Having recently lost an election of my own, I'm expanding my web store. Check back intermittently - prints will be available soon!</p>
    </Layout>
  );
}
